
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.cheap_invoice {
	a {
		font-size: 16px;
		text-decoration: underline dotted;
		display: inline-block;
		color: #444;

		&:hover {
			color: $bk;
		}
	}
}

.product-block {
	position: relative;
	background-color: $gbg1;

	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0 20px 20px;
	}
	.bottom {
		display: block;
	}
	.name {
		margin: 10px 0;
		font-size: 20px;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 400;
		font-family: $hf;
		line-height: 28px;
		a {
			color: $black;
		}
	}
	.price {
		font-size: 34px;
		font-family: "oswald", sans-serif;
		font-weight: 500;
		.price-old {
			font-size: 18px;
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
	}
	.top {
		position: relative;
		padding-bottom: 25px;
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	/*     z-index: 99; */
	padding-bottom: 15px;
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.new_label {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99;
	@include size(94px, 94px);
	background: url('/image/i/new.svg') transparent no-repeat top left;
	.product-label-new {
		color: $lb;
		font-size: 16px;
		transform: rotate(-45deg);
		font-weight: bold;
		display: inline-block;
		top: 25px;
		position: absolute;
		left: -2px;
		text-transform: uppercase;
	}
}

.scroll-button {
	margin-top: 20px;
	float: right;
}

.product-label {
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	position: absolute;
	color: $bk;
	font-size: 16px;
	z-index: 500;
	line-height: 17px;
	padding: 48px 35px 5px 35px;
	width: 150px;
	height: 70px;
	&.sale {
		top: -13px;
		background-color: $theme-color;
		right: -53px;
		transform: rotate(45deg);
	}
	&.bts {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 46px;
		height: 20px;
		position: absolute;
		top: 10px;
		background-color: $theme-color;
		right: 10px;
		border-radius: 2px;
		line-height: 20px;
		.product-label-special {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
	&.flag {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 60px;
		height: 30px;
		position: absolute;
		top: 15px;
		background-color: $brand-success;
		left: 0;
		line-height: 30px;
		&:before {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			top: 0;
			border-top: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			bottom: 0;
			border-bottom: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
	}
}

.price-old {
	text-decoration: line-through;
	color: #666;
}

.func_list {
	display: flex;
	ol {
		font-size: 18px;
	}
	img {
		max-height: 400px;
	}
}

#sbscroll {
	max-height: 400px;
	flex: 0 1 500px;
}

.param_list {
	ul {
		font-size: 18px;
		list-style: none;
		padding-left: 15px;
		li.double {
			span {
				&.param_k {
					color: $lb;
					font-weight: 600;
				}
			}
		}
		li.single {
			.fa-check {
				color: $lb;
				margin-right: 6px;
				vertical-align: top;
				line-height: 29px;
			}
			.param_v {
				display: inline-block;
				max-width: calc(100% - 30px);
			}
		}
	}
}

.cheap_invoice:first-child {
	margin-bottom: 15px;
}

.storage_widget {
	border-top-color: #ddd;
	& > span:nth-child(2) {
		color: $lb !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td {
			&:first-child {
				span {
					border: 0 !important;
					border-radius: 50% !important;
					display: inline-block;
					height: 10px;
					width: 10px;
					background: #a5a5a5 !important;
					vertical-align: -1px;
				}
			}
			&:nth-child(2) {
				padding-left: 0 !important;
			}
			&:last-child span {
				background: $theme-color !important;
				border-radius: 0 !important;
				font-size: 14px !important;
				text-transform: uppercase;
				font-family: $hf !important;
				color: $bk !important;
				@include transition(all .2s ease-in-out);
				&:hover {
					background: $bk !important;
					color: $wh !important;
				}
			}
		}
	}
}
