body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	border: 1px $lb solid;
	border-radius: 3px;
	input.form-control {
		background: transparent;
		border-radius: 0;
		border: none;
		color: $dg;
		font-style: normal;
		font-size: 13px;
		@include placeholder($lg);
	}
	.btn-search {
		display: block;
		background: transparent;
		font-size: 18px !important;
		cursor: pointer;
		@include transition(all 0.2s ease-in-out);
		color: $lg;
		&:hover {
			color: $bk;
		}
	}
}

.form-control::-moz-placeholder {
	color: #aaa;
}

// module cart
#cart {
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
	}
	.icon-cart {
		background: transparent;
		@include size(42px, 47px);
		display: inline-block;
		margin-right: 10px;
		position: relative;
		text-align: right;
		i {
			font-size: 30px;
			padding: 8px 0;
		}
	}
	.wrap-cart {
		display: inline-block;
		color: $wh;
		span {
			position: relative;
			top: 13px;
		}
		.cart-total {
			font-weight: bold;
			line-height: 23px;
			text-transform: none;
		}
		.badge {
			position: absolute !important;
			top: 5px !important;
			right: 3px;
			padding: 3px;
			min-width: 17px;
			line-height: 11px;
			z-index: 99;
			background: $theme-color;
			color: $bk;
			font-size: 11px;
			font-weight: 600;
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.cart-inner {
		text-align: right;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include rounded-corners(3px);
			border: 2px $lb solid;
		}
	}
}