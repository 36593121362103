/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-md-min) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-left {
		left: 0;
	}
	.row-offcanvas-left .sidebar-offcanvas {
		left: -100%;
	}
	.row-offcanvas-left.active {
		left: 0;
		.sidebar-offcanvas {
			left: 0;
		}
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 110px;
		width: 100%;
		z-index: 9999;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
#offcanvasmenu {
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	.fa-minus-square:before {
		content: "\f068";
	}
	.fa-plus-square:before {
		content: "\f067";
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			background-color: $white;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 14px 20px;
				position: absolute;
				right: 0;
				z-index: 1;
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $bk;
				font-size: 16px;
				font-family: $hf;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		padding: 0;
		border-bottom: 1px solid #ccc;
		@include opacity (1);
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			color: $text-color;
			img {
				width: 160px;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	ul.cat_all {
		display: flex;
		flex-wrap: wrap;
		li {
			width: calc(100% / 3);
			padding: 12px 0 0 0;
			border: none;
			a {
				font-size: 14px;
				font-family: $hf;
				text-align: center;
				span {
					display: block;
					text-transform: uppercase;
				}
			}
			i {
				display: none;
			}
		}
		ul.children {
			display: none;
		}
	}
}

.menuwall {
	visibility: hidden;
	opacity: 0;
	background: rgba(0, 0, 0, .5);
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 9998;
	left: 70vw;
}

.row-offcanvas.active .menuwall {
	width: 100%;
	height: calc(100% - 110px);
	text-align: center;
	position: absolute;
	top: 110px;
	visibility: visible;
	opacity: 1;
	left: 0;
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $lb;
	font-size: 28px;
	padding: 0 10px;
	border: none;
	position: relative;
	left: -10px;
	.row-offcanvas.active & .fa-bars:before {
		content: '\f00d';
	}
}

@media (max-width: 480px) {
	#offcanvasmenu ul.cat_all li {
		width: 50%;
	}
}