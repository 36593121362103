@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	.pav-megamenu .navbar-nav > li.aligned-left .dropdown-menu {
		width: 961px !important;
		left: -155px;
	}
}

@media (min-width: 1100px) {
	.pav-megamenu .navbar-nav > li.aligned-left .dropdown-menu {
		left: -60px;
	}
}

@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu {
		#bs-megamenu {
			margin-right: 7px;
		}
		.navbar-nav > li > a {
			padding: 29px 9px;
			font-size: 16px;
			&.dropdown-toggle {
				padding-right: 20px;
			}
			.caret::before {
				left: 3px;
			}
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 265px;
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 170px;
			margin-right: 5px;
			span {
				font-size: 8px;
				letter-spacing: 1px;
				left: 16px;
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.header-top {
		.topcontacts {
			&.phone {
				flex: 0 0 155px;
			}
			&.worktime {
				flex: 0 0 200px;
			}
			.media-title {
				padding-top: 6px;
			}
			.media-body {
				span {
					font-size: 12px;
					line-height: 12px;
					vertical-align: -2px
				}
			}
		}
		.btn-group {
			margin-right: 10px;
		}
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.footer-name {
			img {
				width: 300px;
			}
			span {
				display: block;
			}
		}
		.panel {
			margin-bottom: 15px;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.action > div {
				padding-right: 2px;
			}
		}
	}
	.header-bottom {
		height: 60px;
		.container-fluid {
			height: 100%;
		}
		.main-menu {
			order: 1;
			flex: 0 0 45px;
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $lb;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
					a {
						color: $wh;
					}
				}
			}
		}
		.nav-search {
			order: 3;
			flex: 0 0 165px;
		}
		.logo-container {
			order: 2;
			flex: 1 1 auto;
			text-align: center;
			span {
				font-size: 10px;
				letter-spacing: 2px;
				left: 26px;
				top: -4px;
			}
		}
	}

	body .mfilter-free-container {
		top: 163px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		#mfilter-free-container-0 {
			padding: 0 5px !important;
			overflow: auto !important;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border-color: $theme-color;
			&:before {
				content: '\f0b0';
				font-family: 'Fontawesome';
				color: $white;
				font-size: 24px;
				line-height: 23px;
				padding: 3px;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.h1_container h1 {
		padding: 15px;
		font-size: 30px;
	}
	.product-compare-header {
		width: 60px;
		position: absolute;
		top: 0;
		right: 140px;
		background: transparent;
		&:hover {
			background: transparent;
		}
		a {
			color: $wh;
		}
		i {
			font-size: 28px;
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 10px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.pagescarousel .pc_wrapper {
		flex-wrap: wrap;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 100%;
			}
			&.pc_image {
				order: 1;
				padding: 0;
			}
			&.pc_text {
				order: 2;
			}
		}
	}
	.func_list {
		flex-wrap: wrap;
		& > div {
			width: 100%;
		}
		img {
			max-width: 100%;
			max-height: unset;
		}
	}
	#powered {
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.product-compare-header {
		right: 75px;
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				padding: 0 !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	#powered {
		.inner {
			align-items: flex-start;
			flex-direction: column;

			& > div {
				padding: 0;
			}
			.copyright {
				order: 2;
				margin-bottom: 15px;
			}
			.payment {
				order: 1;
			}
		}
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
				margin-top: 30px;
				.box-heading {
					margin-bottom: 10px;
				}
			}
		}
	}
	.product-info {
		.title-product, .price.detail .price-new {
			font-size: calc(14px + 3vw);
		}
		.price.detail .price-old {
			font-size: calc(14px + 2vw);
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
	}
}

@media (max-width: 570px) {
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.worktime {
		display: none;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
	}
	#searchtop {
		border: 0;
		input.form-control {
			opacity: 0;
			visibility: hidden;
			background: $wh;
			width: 40px;
			position: absolute;
			right: 0;
			top: -7px;
			background: $wh;
			padding: 5px 10px;
			z-index: 2;
			@include transition(0.2s all);
			&.srch {
				width: 210px;
				opacity: 1;
				visibility: visible;
				& ~ .srchwall {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.srchwall {
			position: absolute;
			width: 150vw;
			height: 60px;
			background: transparentize($bk, .5);
			left: calc(-120vw + 48px);
			top: -18px;
			z-index: 0;
			visibility: hidden;
			opacity: 0;
			@include transition(0.2s all);
		}
		.input-group-btn {
			z-index: 3;
		}
		.btn-search {
			&:hover {
				color: #acacac;
			}
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.header-top .headercart {
		flex: 1 0 120px;
	}
	.fancy_holiday_works {
		width: 90% !important;
		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		width: 100%;
		td {
			font-size: 13px;
		}
	}
	.product-grid {
		.product-block {
			width: 100%;
		}
	}
}

@media (max-width: 425px) {
	.product-markdown-view {
		.quantity {
			flex-direction: column;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.header-bottom .logo-container span {
		font-size: 2.5vw;
		top: 0;
		left: calc(19px + 100vw / 320);
	}
	#button-cart {
		padding: 4px 41px;
	}
}
