@font-face {
	font-family: 'Arial';
	src: url('/fonts/arial/subset-ArialMT.eot');
	src: url('/fonts/arial/subset-ArialMT.eot?#iefix') format('embedded-opentype'),
	url('/fonts/arial/subset-ArialMT.woff2') format('woff2'),
	url('/fonts/arial/subset-ArialMT.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial';
	src: url('/fonts/arial/subset-Arial-BoldMT.eot');
	src: url('/fonts/arial/subset-Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
	url('/fonts/arial/subset-Arial-BoldMT.woff2') format('woff2'),
	url('/fonts/arial/subset-Arial-BoldMT.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('/fonts/oswald/subset-Oswald-Regular.eot');
	src: url('/fonts/oswald/subset-Oswald-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/oswald/subset-Oswald-Regular.woff2') format('woff2'),
	url('/fonts/oswald/subset-Oswald-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Oswald';
	src: url('/fonts/oswald/subset-Oswald-Medium.eot');
	src: url('/fonts/oswald/subset-Oswald-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/oswald/subset-Oswald-Medium.woff2') format('woff2'),
	url('/fonts/oswald/subset-Oswald-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}