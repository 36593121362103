$dg: #393c43;
$bk: $dg;
$black: $dg;
$wh: #fff;
$mg: #3f3f3f;
$lb: #82b4bd;
$tc: #ffcf06;
$bc: #7e8085;

$gbg1: #fafafa;
$gbg2: #f3f3f3;
$gbg3: #e1e1e1;

$f: 'Arial', sans-serif;
$hf: 'Oswald', sans-serif;
$fa: 'FontAwesome';

@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "fonts";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx";
@import "opencart/modules/simplebar.scss";

@import "app/custom";
@import "opencart/responsive";