.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 5px;
			line-height: normal;
			position: relative;
			width: calc(100% / 9);
			a {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				line-height: 18px;
				padding: 10px 15px;
				span {
					display: block;
					text-align: center;
					font-weight: 400;
					width: 100%;
					font-size: 16px;
					text-transform: uppercase;
					font-family: $hf;
				}
			}
			@media (max-width: 1199px) {
				width: calc(100% / 7);
			}
			@media (max-width: 991px) {
				width: calc(100% / 6);
			}
			@media (max-width: 767px) {
				width: calc(100% / 5);
			}
			@media (max-width: 640px) {
				width: calc(100% / 4);
			}
			@media (max-width: 500px) {
				width: calc(100% / 3);
			}
			@media (max-width: 400px) {
				padding: 7px 10px;
				a span {
					font-size: 14px;
				}
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $bk;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-left: 5px;
		font-family: $hf;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #ccc;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 24px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $bk;
			@include box-shadow (none);
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding: 0 20px;
	}
	.product-block {
		display: flex;
		border: 1px solid #eee;
		margin: 10px 0;
		border-radius: 0;
		.name {
			font-size: 30px;
			line-height: 1.2;
			margin-bottom: 15px;
		}
		.priice {
			line-height: 1.5;
		}
		.description {
			font-size: 16px;
			margin-top: 15px;
		}
		.block-img {
			flex: 0 0 30%;
			overflow: hidden;
			position: relative;
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			& > div {
				flex: 0 0 150px;
			}
			.bigbtn {
				height: 40px;
				line-height: 24px;
				display: inline-block;
			}
			button.bigbtn {
				line-height: 17px;
			}
			.action {
				display: inline-block;
				padding: 4px 0;
				text-align: right;
				order: 2;
				& > div {
					display: inline-block;
				}
			}
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.product-grid {
	.product-block {
		margin: 0 10px 10px 0;
		width: calc(100% / 3 - 10px);
		&:nth-child(3n) {
			margin-right: 0;
		}
		@media(max-width: 991px) {
			width: calc(100% / 2 - 7px);
			&:nth-child(3n) {
				margin-right: 10px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@media(max-width: 767px) {
			.price {
				font-size: 28px;
			}
		}
	}
}

.product-grid[id^="no-scroll"] {
	margin-top: 45px;
	.product-block {
		margin: 0 7px 10px 0;
		width: calc(100% / 4 - 10px);
		&:nth-child(4n) {
			margin-right: 0;
		}
		@media(max-width: 1199px) {
			width: calc(100% / 3 - 7px);
			&:nth-child(4n) {
				margin-right: 7px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		@media(max-width: 991px) {
			&:nth-child(3n) {
				margin-right: 7px;
			}
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}

.pb30 {
	padding-bottom: 30px;
}

.functions {
	position: absolute;
	z-index: 99;
	bottom: 0;
	left: 20px;
	@include rounded-corners(50%);
	@include size(60px, 60px);
	border: 2px solid $lb;
	font-family: $hf;
	text-align: center;
	background: $wh;
	.product-info & {
		bottom: 20px;
	}
	span {
		display: inline-block;
		position: relative;
		line-height: 1;
		color: $lb;
		&.count {
			font-size: 21px;
			top: 7px;
			width: 100%;
		}
		&.capt {
			font-size: 12px;
			top: -1px;
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
	}
	.product-block {
		display: inline-block;
		@include transition(box-shadow 0.2s ease-in-out);
		padding: 0;
		overflow: hidden;
		.price {
			height: 20px;
		}
		.block-img {
			position: relative;
		}
		.cart {
			padding: 0;
			.bigbtn {
				width: 120px;
			}
		}
		.bottom {
			display: flex;
			justify-content: space-between;
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			text-align: left;
			padding: 0;
			.bigbtn {
				height: 40px;
				line-height: 24px;
				display: inline-block;
			}
			button.bigbtn {
				line-height: 17px;
			}
			.action {
				text-align: left;
				& > div {
					display: inline-block;
					vertical-align: top;
				}
			}
		}
		&:hover {
			background-color: $gbg2;
			z-index: 1;
		}
		.image {
			.action {
				position: absolute;
				top: 15px;
				right: 15px;
				width: 40px;
				button, a {
					border: 0;
					background: rgba(255, 255, 255, .5);
					@include transition(all 0.2s ease-in-out);
					&:hover {
						background: rgba(255, 255, 255, 0.2);
						color: $theme-color;
					}
					&:focus {
						background: rgba(255, 255, 255, .5);
						color: $bk;
					}
				}
			}
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
}

.owl-carousel {
	.product-block {
		width: 100%;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

.product-info {
	margin-top: 24px;
	.quick-view & {
		margin: 20px;
	}
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 40px;
		margin-top: 0;
		font-weight: 500;
		padding: 0;
	}
	.check-box {
		color: $lb;
	}
	#product {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.image-additional {
		overflow: hidden;
		padding: 0 30px;
		.owl-carousel {
			margin-bottom: 0 !important;
			.owl-prev, .owl-next {
				top: calc(50% - 23px);
			}
		}
		.item {
			text-align: center;
			margin: 0 2px;
			background-color: $white;
			position: relative;
			a {
				display: inline-block;
				border: 1px solid $bc;
				&.active {
					border: 2px $lb solid;
				}
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity {
		display: flex;
		gap: 8px;
	}
	.quantity-adder {
		display: flex;
		width: max-content;
		position: relative;
		background-color: $white;
		border: 2px $lg solid;
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			line-height: 39px;
			text-align: center;
			font-size: 14px;
		}
		.quantity-number {
			input {
				font-family: $hf;
				@include size(40px, 39px);
				text-align: center;
				font-size: 16px;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		display: flex;
		flex-direction: column;
		gap: 16px;

		a {
			a.btn-sm {
				padding: 0 19px;
			}
		}
		.quantity {

		}
		.cart {
			padding-bottom: 0;
			display: flex;
			gap: 8px;
		}
		.btn-compare {
			color: $bk;
			font-size: 20px;
			padding: 2px 18px;
			height: 43px;
			display: inline-block;
			line-height: 30px;
		}
		.button-credit {
			height: 43px;
		}
	}

	.price {
		font-family: $hf;
		font-weight: 500;
		display: flex;
		gap: 8px;
		align-items: flex-end;

		.price-new {
			color: $black;
			font-size: 24px;
		}
		&.detail {
			.price-new {
				font-size: 40px;
				line-height: 1;
			}
			.price-old {
				font-size: 26px;
				line-height: 1;
			}
		}
	}
	.tab-content {
		border: none;
		padding: 0;
		margin-bottom: 30px;
		.desc-header-wrapper {
			border-bottom: 1px $bc solid;
			margin-top: 30px;
			margin-bottom: 15px;
			.desc-header {
				padding: 15px;
				color: $lb;
				font-family: $hf;
				text-transform: uppercase;
				font-size: 30px;
				position: relative;
				top: 1px;
				display: inline-block;
			}
		}
		#tab-description {
			padding: 0 15px;
			p {
				font-size: 18px;
			}
		}
		#tab-cert {
			img {
				max-width: 50%;
			}
		}
		.for-send-error-press {
			font-style: italic;
			margin: 15px 0;
		}
	}
	.desc-pane .widget-product {
		margin: 0 15px;
	}
	.avail_colors {
		ul {
			list-style: none;
			padding: 10px 0 0 0;
			li {
				display: inline-block;
				position: relative;
				margin-right: 5px;
				&:before {
					@include size(49px, 49px);
					border: 1px solid $bk;
					@include rounded-corners(50%);
					background: $wh;
					position: absolute;
					z-index: 1;
					top: -2px;
					left: -2px;
				}
				a {
					position: relative;
					display: inline-block;
					@include size(45px, 45px);
					@include rounded-corners(50%);
					z-index: 2;
				}
				&:first-child {
					&:before {
						content: ' ';
					}
				}
			}
		}
	}
}

.product-markdown-view {
	.cheaper-item {
		padding: 20px;
		border: 1px solid rgba(57, 60, 67, 0.35);
		max-width: 350px;

		&.general {
			border: 1px solid $bc;
		}
		&:not(.general) {
			.price-new {
				font-size: 28px;
			}
			.price-old {
				font-size: 20px;
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
	.cheaper-item, .cheaper-products, .cheaper-items, .cheaper-item-info {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.cheaper-item, .cheaper-products {
		&__title {
			font-size: 16px;
			font-weight: 600;
		}
		&__reason {
			color: $lb;
		}
		&__status {
			font-weight: 600;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	font-size: 26px;
	background: $gbg;
	h1 {
		margin: 0 auto;
		font-size: 46px;
		font-weight: 500;
		text-transform: uppercase;
		padding: 30px 15px 33px 15px;
		line-height: 1;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

.information-opt {
	.pricelist {
		i {
			padding-right: 15px;
			vertical-align: 2px;
			margin-top: 15px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.optlogo {
		width: 70%;
	}
	.pl_contact {
		display: flex;
		align-items: center;
		margin: 10px 0;
		i {
			flex: 0 0 35px;
			font-size: 20px;
		}
		.pl_contact_val {
			font-size: 16px;
			display: flex;
			flex-wrap: wrap;
			span {
				flex: 100%;
			}
		}
	}
}
