//
// Navs
// --------------------------------------------------
$hot-color: #ff9800 !default;
.badges {
	&.new, &.hot {
		color: $white;
		font-size: $font-size-base - 3;
		height: 15px;
		line-height: 16px;
		padding: 0 5px;
		position: absolute;
		left: 20px;
		@include rounded-corners(2px);
		text-align: center;
		text-transform: uppercase;
		top: -5px;
		transform: translateZ(0px);
		font-family: $font-family-base;
		font-weight: 500;
		&:before {
			border-style: solid;
			border-width: 2px;
			bottom: -5px;
			content: "";
			left: 4px;
			position: absolute;
		}
	}

	&.new {
		background-color: $brand-info;
		&:before {
			border-color: $brand-info transparent transparent;
		}
	}

	&.hot {
		background-color: $hot-color;
		&:before {
			border-color: $hot-color transparent transparent;
		}
	}

}

.red {
	.menu-title {
		color: $red;
	}
}

.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		font-family: $hf;
		> li {
			height: 80px;
			&.aligned-left {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}
			&:before, &:after {
				content: '';
				height: 5px;
				width: 0;
				background-color: $tc;
				@include transition(all 200ms ease-out);
				position: absolute;
				bottom: 0;
			}
			&:before {
				left: 50%;
			}
			&:after {
				right: 50%;
			}
			&:hover, &:focus, &:active {
				&:before, &:after {
					width: 50%;
				}
			}
			> a {
				padding: 29px 15px;
				font-size: 18px;
				color: $dg;
				background-color: transparent !important;
				text-transform: uppercase;
				@include transition(all 200ms ease-out);
				line-height: 22px;
				&.dropdown-toggle {
					padding-right: 30px;
				}
				.caret {
					border: 0;
					position: relative;
					&:before {
						@include transition(all 200ms ease-out);
						content: '\f107';
						font-family: 'FontAwesome';
						position: absolute;
						left: 8px;
						top: -13px;
						font-size: 20px;
						font-weight: 100;
						color: $dg;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		color: $text-color;
		border-top: none;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		ul.cat_all {
			display: flex;
			flex-wrap: wrap;
			a.level1 {
				padding: 12px 0 0 0;
				> span {
					text-align: center;
					font-size: 14px;
					text-transform: uppercase;
				}
				> img {
					margin-top: 15px;
					width: 160px;
				}
			}
		}
		li {
			padding: 0;
			line-height: 20px;
			list-style: none;
			position: relative;
			a {
				padding: 12px 20px;
				display: inline-block;
				width: 100%;
				color: $dg;
				font-size: 14px;
				font-weight: 400;
				span {
					display: block;
				}
			}
			> ul.children {
				> li {
					a {
						padding: 4px 12px;
						color: $dg;
						font-family: $f;
						text-transform: none;
						font-size: 14px;
					}
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}