.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;
	border-radius: $border-radius-base;

	> li {
		display: inline; // Remove list-style and block-level defaults
		> a,
		> span {
			position: relative;
			float: left; // Collapse white-space
			margin-right: 8px;
			line-height: 28px;
			width: 40px;
			height: 34px;
			text-decoration: none;
			text-align: center;
			color: $bk;
			font-size: 16px;
			font-family: $hf;
		}
		&:first-child {
			> a,
			> span {
				margin-left: 0;
			}
		}
	}

	> li > a,
	> li > span {
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			color: $bk;
			text-decoration: none;
			background-color: $tc;
			border-color: $tc;
			cursor: default;
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			color: $pagination-disabled-color;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border;
			cursor: $cursor-disabled;
		}
	}
}

.countresults {
	padding: 7px 0;
}

.pagination-lg {
	@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

.pagination-sm {
	@include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
