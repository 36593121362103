$app-brand-prefix        		: pav !default;
$block-prefix 			 		: panel !default;
$block-selector          		: panel !default;
$block-heading-selector  		: panel-heading !default;
$block-content-selector  		: panel-body !default;
$products-container-selector 	: products_block !default;
$products-row-selector 		 	: products-row !default;
$products-col-selector 		 	: product-col !default;
$image-path:                    "images/"!default;
$font-custom-path:              "../font/"!default;
$image-theme-base-path:         "../img/"!default;
$image-theme-icon-path:         "../img/icon/"!default;
$image-base-path:               "../../../img/"!default;
$image-skin-path:               "../../img/icon/"!default;
$image-module-path:             "../../../img/icon/"!default;
$module-path:                   "../../../modules/"!default;

$image-theme-path:              "../image/" !default;
$image-theme-path-base: 		"../image/" !default;

$lg: #acacac;
$gbg: #f3f3f3;

$sky:                  			    #7bbd42 !default;
$blue:                              #607d8b !default;
$yellow:                  			#ffc801 !default;
$coral:								#EE6C92 !default;
$rico:								#51BAA9 !default;
$jungle-green:      				#37bc9b !default;
$green:                    			#00bfa5 !default;
$red:                   			#fd5c6d !default;
$pink:                  			#e34d86 !default;
$white:			 				    #FFFFFF !default;
$black:							    #141414 !default;
$cyan:   					    	#00b8c4 !default;
$orange:                	 		#ff9800 !default;
$aqua: 								#795548 !default;
$turquoise:                         #4ACFD4 !default;
$nav-border-color-hover:            #e2e2e2 !default;
$golden-poppy:                      #F2BE01 !default;
$valencia:      					#D44A4A !default;
$tundora:                           #9c9c9c !default;
$wild-sand:                         #ffffff !default;
$silver:                            #f5f5f5 !default;
$red-light:            				#ff9999 !default;
$yellow-light:           			#ffdc73 !default;
$nocolor:							transparent !default;
$border-color-base: #e5e5e5 !default;
$font-tamoha: "tahoma";
$font-arial: "Arial";
$font-family:			 			 $f, sans-serif !default;
$font-family-secondary :             "Lato", sans-serif !default;
$font-family-tertiary  :             $font-family !default;
$font-family-senary    : 		 	 $font-family !default;
$font-family-septenary : 			 $font-family !default;
$font-icon-family 	   : 			 "FontAwesome" !default;
$font-icon-secondary   :			 "Material Design Iconic Font"!default;
$body-bg:                           #fff !default;
$theme-color:  				        $tc !default;

$theme-color-default  : 			#101010 !default;
$theme-color-primary  :				$theme-color !default;
$theme-color-secondary:             #505050 !default;
$theme-color-tertiary :             #bbb !default;
$theme-color-lighten  :				#ff5346 !default;
$theme-color-darken   :				#e14236 !default;
$theme-color-darker	  :             #e83325 !default;


$font-body-family	   : $font-family !default;
$font-headding-family  : $font-family-secondary !default;
$font-menu-family      : $font-family-secondary !default;
$font-highlighted-family: $font-family-secondary !default;


$theme-bg-default		: $wh !default;
$theme-bg-secondary 	 : $red !default;
$theme-bg-threedary  	 : $brand-warning!default;


$border-color  : #ebebeb !default;

$table-bg-color	:  #ebebeb !default;



$btn-hover-cart : $theme-color !default;

$btn-hover-cart-border : #2a7abb !default;

$brand-border:                     1px solid $border-color !default;
$brand-radius:                     3px !default;

$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
