/* carousel-controls */
.carousel-controls {
	.carousel-control {
		z-index: 99;
	}
}

.carousel-controls-small {
	width: 65px;
	position: relative;
	display: inline-block;
	.carousel-control-small {
		@include size(28px, 28px);
		line-height: 26px;
	}
}

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bk;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $wh;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

.breadcrumbs {
	padding: 10px 0;
	margin-bottom: 0;
	list-style: none;
	border-radius: $border-radius-base;
	&+.container-fluid {
		min-height: calc(100vh - 464px);
	}
	.breadcrumb {
		margin: 0;
		padding: 0;
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
	.breadcrumb-links {
		margin: 0;
		position: relative;
		padding: 0 0 10px;
		border-bottom: 1px solid $border-color-base;
		> li {
			display: inline-block;
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-base - 2;
			}
			+ li:before {
				color: #757575;
				content: "\f105";
				font-family: FontAwesome;
				padding: 0 15px;
				font-size: 14px;
			}
			&:last-child {
				a {
					color: $black;
				}
			}
		}
		> .active {
			color: $link-hover-color;
		}
	}
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

.alert-box-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-box-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-box-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-box-error {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

.table {
	margin-bottom: 0;
}

.table > thead > tr > th {
	border-width: 1px;
	color: $bk;
	font-weight: 600;
}

.table > tbody > tr > td {
	color: $bk;
	vertical-align: middle;
}

.tab-v3 {
	.tab-content {
		padding: 0;
	}
	.nav-tabs {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #acacac;
		> li {
			top: 1px;
			border: none;
			float: none;
			> a {
				font-size: 18px;
				font-weight: 600;
				padding: 15px 30px;
				background: transparent;
				text-transform: uppercase;
				line-height: 1.1;
				color: $lg;
				border: 1px $lg solid;
				border-right: none;
			}
			&:last-child {
				border-right: 1px $lg solid;
			}
			&.active > a {
				color: $tc;
				position: relative;
				border-top-color: #fff;
				border-bottom: 1px solid $tc;
			}
		}
	}
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #E4E3E3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 18px;
	color: $bk !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	a {
		text-decoration: none !important;
		font-size: 18px;
		color: $bk !important;
		&:hover {
			text-decoration: underline !important;
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		@include size(20px, 20px);
		cursor: pointer;
	}
	.success_button {
		@include transition(0.2s all);
		background: $theme-color;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: $bk !important;
		font-size: 18px;
		font-weight: 400;
		padding: 12px 15px;
		text-decoration: none;
		text-transform: uppercase;
		font-family: $hf;
		&:hover {
			text-decoration: none !important;
			background: $bk;
			color: white !important;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}